
// sessionStorage.setItem('prefetchViaByAjax', '1')
import { log, throttles } from './utils.js'

function supports(relType) {
  if (Boolean(sessionStorage.getItem('prefetchViaByAjax'))) return false
  const link = document.createElement('link')
  return link.relList && link.relList.supports && link.relList.supports(relType)
}

function viaDOM({ url, relType = 'prefetch', as = 'script' }) {
  log(`via dom ...${url}`)
  return new Promise((resolve, reject) => {
    let link = document.createElement('link')
    link.rel = relType
    link.href = url
    link.as = as
    link.onload = (event) => {
      resolve(event)
    }
    link.onerror = (event) => {
      reject({
        event,
        url
      })
    }
    document.head.appendChild(link)
  })
}

// Prevent secondary requests
let isFetchingResourceMap = {}
typeof window !== 'undefined' && (window.isFetchingResourceMap = isFetchingResourceMap)

function viaXHR({ url }) {
  log(`via xhr ...${url}`)

  if (!Object.prototype.hasOwnProperty.call(isFetchingResourceMap, url)) {
    isFetchingResourceMap[url] = []
  }

  const promise = new Promise((resolve, reject) => {
    isFetchingResourceMap[url].push({ resolve, reject })
    
    // has one request is loading
    if (isFetchingResourceMap[url].length > 1) return

    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    
    request.onload = (event) => {
      if (request.status === 200 || request.status === 304) {
        isFetchingResourceMap[url].forEach(({ resolve }) => resolve({ event, url }))
      } else {
        isFetchingResourceMap[url].forEach(({ reject }) => reject({ event, url }))
      }
      isFetchingResourceMap[url] = []
    }

    request.onerror = (event) => {
      isFetchingResourceMap[url].forEach(({ reject }) => reject({ event, url }))
      isFetchingResourceMap[url] = []
    }

    request.send()
  })
  
  return promise
}

// 默认限制5条
const [toAdd, isDone] = throttles(5)

// 基于浏览器兼容性判断，用xmlhttprequest做兜底
const viaHandle = (relType) => {
  if (supports(relType)) {
    return (arg) => {
      return new Promise((resolve, reject) => {
        toAdd(() => {
          viaDOM(arg).then(resolve).catch(reject).finally(isDone)
        })
      })
    }
  } else {
    return (arg) => {
      return new Promise((resolve, reject) => {
        toAdd(() => {
          viaXHR(arg).then(resolve).catch(reject).finally(isDone)
        })
      })
    }
  }
}

export {
  viaHandle
}
