
import { viaHandle } from './viaHandle'
import { ErrorThrow, log } from './utils'

class PrefetchResource {
  #jsMainfest = {}
  #staticDomain = ''
  #isFetchingResourceMap = new Map()
  #hasFetchedResourceMap = new Map()
  constructor({ staticDomain = '', mainfest = {} }) {
    this.#jsMainfest = mainfest?.jsDepend || {}
    this.#staticDomain = staticDomain
    log('manifest', this.#jsMainfest)
  }
  
  prefetchJs({ prefetchList = [] }) {
    if (prefetchList.length <= 0) return Promise.reject({ error: 'prefetchList is empty' })
    const promises = []
    prefetchList.forEach(item => {
      const { chunkName = '', url = '', relType = 'prefetch' } = item
      if (relType !== 'prefetch' && relType !== 'preload') ErrorThrow('prefetchJs: rel must be prefetch or preload')
      if (!chunkName && !url) ErrorThrow('prefetchJs: miss chunkName or url')
      if (chunkName) {

        // If the chunkname has been requested, then return the promise
        if (this.#hasFetchedResourceMap.has(chunkName)) {
          promises.push(this.#hasFetchedResourceMap.get(chunkName))
          return
        }

        const chunkDependInfo = this.#jsMainfest[chunkName] || []
        const promiseGroupByChunk = []
        chunkDependInfo.forEach(chunkPath => {
          const promise = viaHandle(relType)({
            url: `${this.#staticDomain}${chunkPath}`,
            relType,
            as: 'script'
          })
          promiseGroupByChunk.push(promise)
        })

        // Records the chunkname being requested
        this.#isFetchingResourceMap.set(chunkName, promiseGroupByChunk)

        // Records the fetched chunkname
        this.#hasFetchedResourceMap.set(chunkName, promiseGroupByChunk)

        promises.push(
          Promise.all(promiseGroupByChunk).finally(() => {
            // Remove the chunkname being requested
            this.#isFetchingResourceMap.delete(chunkName)
          }) 
        )
      } else if (url) {
        const { url = '', relType = 'prefetch' } = item
        if ((relType !== 'prefetch' && relType !== 'preload')) ErrorThrow('prefetchJs: rel must be prefetch or preload')

        // If the chunkname has been requested, then return the promise
        if (this.#hasFetchedResourceMap.has(url)) {
          promises.push(this.#hasFetchedResourceMap.get(url))
          return
        }

        const promise = viaHandle(relType)({
          url: `${this.#staticDomain}${url}`,
          relType,
          as: 'script'
        })
        // Records the url being requested
        this.#isFetchingResourceMap.set(url, promise)

        // Records the fetched chunkname
        this.#hasFetchedResourceMap.set(url, promise)

        promises.push(
          promise.finally(() => {
            // Remove the url being requested
            this.#isFetchingResourceMap.delete(url)
          })
        )
      }
    })

    return Promise.all(promises)
  }
  /**
   * @param {*}  
   * @param {String} [chunkName] 跟webpackChunkName保持一致, ex: chunkName: 'onepage', 
   * @param {Function} [componentFactory] 异步importfunction, ex: () => import( /* webpackChunkName: 'onepage' * / './pages/one.vue')
   */
  importAsyncComponent({ chunkName = '', componentFactory = '' } = {}) {
    if (!chunkName || !componentFactory) ErrorThrow('prefetchJs: miss chunkName or componentFactory')

    return () => new Promise(resolve => {
      const isFetch = this.#isFetchingResourceMap.get(chunkName)
      if (!isFetch) {
        resolve(componentFactory())
      } else {
        Promise.all(isFetch).finally(() => {
          resolve(componentFactory())
        })
      }
    })
  }
  /**
    * @param {Object} options - Configuration options
    * @param {Element} [options.el] - The element to listen for events on
    * @param {Array} [options.prefetchList] prefetchList prefetch resource list
    *   @param {string} [options.prefetchList.chunkName] chunkName, default ''
    *   @param {string} [options.prefetchList.url] url, default ''
    *   @param {string} [options.prefetchList.relType] prefetch or preload
    * @param {Function} [options.prefetchCallback] prefetch callback: ({status}) => {}, status: success || error 
    * @param {Number} [options.throttle] the concurrency limit for prefetching, default 5, max Infinity(enter 0)
    * @param {Number} [options.delay] Time each element needs to stay inside viewport before prefetching (ms), default 2000
    * @param {Object} [options.ioConfig] IntersectionObserver config, default { threshold: 1, rootMargin: '0px 0px 0px 0px' }
    *   @param {Number|Array} [options.ioConfig.threshold] IntersectionObserver config threshold, default 0
    *   @param {String} [options.ioConfig.rootMargin] IntersectionObserver config rootMargin, default 0px 0px 0px 0px
    * @param {Function} [options.ioCallback] IntersectionObserver callback
    */
  listen(options = {}) {
    if (typeof window === 'undefined' || !window.IntersectionObserver || !('isIntersecting' in window.IntersectionObserverEntry.prototype)) return
    if (!options.el || !(options.el instanceof HTMLElement)) ErrorThrow('listen: miss el or el is not HTMLElement')

    const delay = options.delay || 2000
    const iothrottle = options?.ioConfig?.threshold || 0
    const iorootMargin = options?.ioConfig?.rootMargin || '0px 0px 0px 0px'
    const ioCallback = options.ioCallback || (() => {})
    const prefetchCallback = options.prefetchCallback || (() => {})
    
    let setTimeoutIfDelayId = null
    const setTimeoutIfDelay = (fn, delay) => {
      if (delay > 0) {
        return setTimeout(fn, delay)
      }
      fn()
      return null
    }

    const observer = new IntersectionObserver(entries => {
      // there is only one element
      const entry = entries[0]
      if (entry.isIntersecting) {
        setTimeoutIfDelayId = setTimeoutIfDelay(() => {
          log(`listen: isIntersecting`, options.el)
          
          // 就算资源获取失败，也不重复监听
          observer.unobserve(options.el)

          ioCallback()

          this.prefetchJs({ prefetchList: options.prefetchList }).then((res) => {
            prefetchCallback({
              status: 'success',
              info: res
            })
          }).catch(err => {
            prefetchCallback({
              status: 'error',
              info: err
            })
          })
        }, delay)
      } else {
        log(`listen: not isIntersecting`, options.el)
        if (setTimeoutIfDelayId !== null) clearTimeout(setTimeoutIfDelayId)
      }
    }, {
      threshold: iothrottle,
      rootMargin: iorootMargin
    })

    observer.observe(options.el)

    return function() {
      observer.disconnect()
      if (setTimeoutIfDelayId !== null) clearTimeout(setTimeoutIfDelayId)
    }
  }
   
}

export {
  PrefetchResource
}
